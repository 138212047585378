


















































import FullForm from '@/components/Forms/FullForm.vue'
import { parseSuccessMessage, prepareFormForAnswer, prepareFrontendFields } from '@/components/Forms/logic/FormsHelper'
import { Form } from '@/includes/types/Form.types'
import { errorNotification } from '@/includes/services/NotificationService'
import { FormService } from '@/includes/services/FormService'

import DrawerWidthMixin from "piramis-base-components/src/logic/helpers/DrawerWidthMixin";

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    FullForm,
  }
})
export default class FormPreview extends Mixins<DrawerWidthMixin>(DrawerWidthMixin) {
  @Prop({ type: Object }) form!:Form

  @Watch('form', { deep: true })
  onFormChange():void {
    this.frontendFields = this.iterateeFields
  }

  frontendFields = this.iterateeFields

  get iterateeFields() {
    return prepareFrontendFields(this.form.config.fields)
  }

  testForm():void {
    try {
      const answers = prepareFormForAnswer(this.frontendFields)
      let config = cloneDeep(this.form.config)

      config = parseSuccessMessage(config)

      FormService.testForm('tg', {
        board_key: this.$store.state.boardsState.activeBoard!.board,
        name: this.form.name,
        config,
        answer: {
          values: answers
        }
      })
    } catch (e) {
      errorNotification(e)
    }
  }
}
